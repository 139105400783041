<template>
  <div class="box">
    <div class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="" />
    </div>
    <img class="banner" src="/subject/lsy-8901/banner.png" alt="">
    <div id="login">
      <div class="logxiadan">
        <img src="/subject/lsy-8901/500zj.png" alt="">
      </div>
      <div class="interactive">
        <input v-model="form.phone" type="text" placeholder="请输入手机号">
        <div class="codeBox">
          <input v-model="form.code" type="text" placeholder="请输入验证码">
          <span v-if="verControl" @click="verCode(form.phone)">获取验证码</span>
          <span v-else>重新获取({{ verSecond }})</span>
        </div>
        <img class="loginBtn" @click="submit(form)" src="/subject/august/loginBtn.png" alt="">
      </div>
      <div v-if="controlImgCodeDialog" class="codeDialog">
        <img :src="imgcodelocal" alt="">
        <p>
          <span>图形验证码:</span>
          <input type="text" v-model="form.codeNumber">
        </p>
        <div>
          <button @click="() => { this.controlImgCodeDialog = false }">取消</button>
          <button @click="confirm(form.codeNumber)">确定</button>
        </div>
      </div>
      <div class="logdjs">
        倒计时 <span class="logdjs-span">{{ days }}</span><i>天</i><span class="logdjs-span">{{ hours }}</span><i>时</i><span
          class="logdjs-span">{{ minutes }}</span><i>分</i><span class="logdjs-span">{{ seconds }}</span><i>秒</i>
      </div>

    </div>
    <div class="content">
      <div class="parent-container">
        <div class="dujia">
          <img src="/subject/lsy-8901/dujia.png" alt="">
        </div>
      </div>
      <div class="part">
        <div class="part1">
          <div style="padding-top: 183px;">
            <div class="clearfix" style="position: relative;">
              <span class="span1" @click="showDialog = true">活动详情</span>
            </div>

            <div class="img-box1">
              <div><img src="/subject/lsy-8901/100.png" alt=""></div>
              <div><img src="/subject/lsy-8901/300.png" alt=""></div>
              <div><img src="/subject/lsy-8901/200.png" alt=""></div>
            </div>
            <div class="img-box2">
              <img src="/subject/lsy-8901/1000.png" alt="">
              <img class="jia" src="/subject/lsy-8901/jia.png" alt="">
              <img style="margin-left: 50px;" src="/subject/lsy-8901/100meiyuan.png" alt="">
            </div>
            <div class="youhui">
              <img src="/subject/lsy-8901/youhui.png" alt="">
              <div>现金一笔入账<i>（独家优惠）</i></div>
            </div>
            <div class="youhui">
              <img src="/subject/lsy-8901/jiaoyi.png" alt="">
              <div>赠金笔笔提现<i>（可继续交易）</i></div>
            </div>
            <div class="neirong">
              <div style="margin: 0 auto;margin: 0px auto;width: 100%;text-align: center;"><span
                  class="name1">首次入金</span><span class="name2">，充多少送多少</span></div>
              <p style="width: 100%; text-align: center;">充值金额≥$500，额外送<span class="name2">$100</span>美元现金</p>
            </div>
            <div class="anniu">
              <div class="anniu-div"><span @click="live800">咨询客服</span></div>
              <img class="anniu-img" @click="toTop" src="/subject/lsy-8901/lzj.png" alt="">
            </div>
            <div class="hdtext">活动倒计时中，名额有限，先到先得！</div>
          </div>
        </div>
        <div class="part2 clearfix">
          <div class="part2-img">
            <img style="margin-right: 20px;" src="/subject/lsy-8901/feinong.png" alt="">
            <img src="/subject/lsy-8901/yixin.png" alt="">
          </div>
          <div class="part2-img2">
            <img src="/subject/lsy-8901/mm.png" alt="">
          </div>
          <div class="part2-text">
            <span class="part2-text-span1">$10起投</span><span class="part2-text-span2">，小成本炒金，业余时间赚外快</span>
          </div>
          <div class="anniu">
            <div class="anniu-div"><span @click="live800">咨询客服</span></div>
            <img class="anniu-img" @click="toTop" src="/subject/lsy-8901/lzj.png" alt="">
          </div>
        </div>
        <div class="part3">
          <img src="/subject/lsy-8901/jiangshi.png" alt="">
          <img src="/subject/lsy-8901/tese.png" alt="">
          <div>
            <p style="mask-type: 20px;">香港15年品牌，持牌伦敦金/银交易平台</p>
            <p><span>专业平台+专业导师</span>交易安心有保障</p>
            <div class="anniu">
              <div class="anniu-div"><span @click="live800">咨询客服</span></div>
              <img class="anniu-img" @click="toTop" src="/subject/lsy-8901/lzj.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sliderBox" v-if="slider">
      <img class="allCode" src="/subject/invest/downloadNew.png" alt="">
      <img @click="slider = false" class="user-info-close" src="../../../../static/common/user-info-close.png" alt="">
    </div>
    <div v-if="showDialog" class="dialog">
      <img class="closer" @click="showDialog = false" src="/subject/mayPage/closer.png" alt="">
      <ul class="dialogContent">
        <h3>新用户活动规则</h3>
        <li>
          <span>活动对象：</span><br />
          活动期间，在本平台开立真实账户，并首次入金的客户
        </li>
        <li>
          <span>活动时间：</span><br />
          即日起至{{ this.getLastDay() }}
        </li>
        <li style="color:#ad0000">
          <span>活动规则：</span><br>
          活动期间，新用户可享双重福利：<br>
          1. 开户赠送$500现金券一张，盈利即送3美金；<br>
          2. 首次入金，充多少送多少，交易可返现，<span style="color:#ad0000;font-weight: 500;">上不封顶</span>；
        </li>
        <li>
          <span>开户领现金券活动：</span><br>
          1. 活动期间，开立真实账户赠送500美元现金券一张；<br>
          2. 现金券领取后可直接交易，使用有效期从当日00:00起计算，共计3天；<br>
          3. 每张现金券仅可交易1笔，现金券持仓单需在最近一次04:00前平仓，或由系统自动平仓；<br>
          4. 现金券交易后盈利即送3美金，直接发放到MT账户，用户可直接提现；<br>
          5. 盈利有效期为平仓后30天内，逾期扣回；<br>
          6. 现金券仅限伦敦金/伦敦银交易品种；<br>
        </li>
        <li>
          <span>首入金活动规则：</span><br>
          首次充值，充多少送多少美元赠金，奖励上不封顶；<br>
          72小时内总入金≥500美元，可额外获得100美元现金；<br>
          首入金奖励如下：<br>
        </li>
        <li>
          <img src="/subject/mayPage/table.png" alt="">
        </li>
        <li>
          <span>美元赠金：</span><br>
          1、活动期间内，首次入金可获得等额的美元赠金（即赠金点）回馈；<br>
          2、 首入金有效期为第一笔入金后的72h内，赠送金额将按照72h后最终数额计算；<br>
          3、用户每交易一手伦敦金/伦敦银，返还相对应的赠金金额；该笔资金可交易可提现，用户可登录App-我的-赠金奖励中查看（未满一手按照实际交易手数占一手的比例计算）<br>
          4、美元赠金按交易手数返还，当月交易越多，每手返还越高！<br>
          <span style="color:#000000">
            更多活动详情，请下载App-我的-赠金奖励中查看，或咨询客服。
          </span>
        </li>
        <li>
          <span>美元现金：</span><br>
          1、美元现金直接入账，可交易，可提现；<br>
          2、用户在30天内完成≥10手交易即可提现，品种限伦敦金/伦敦银；<br>
          3、30天内未完成手数要求，活动到期时，系统仅扣回美元现金金额。<br>
          举例说明：A女士，入金520美元，获得100美元现金，账户实际到账金额为520+100=620美元，第15天，A女士完成10手交易，顺利提取100美元现金。
        </li>
        <li>
          <span>注意事项：</span><br>
          1. 客户参与活动需在本公司开立真实交易账户,参与该活动的新用户，为保证资金安全，在初始L1层级，账户强平比例上调至50%，升级后自动恢复为20%；<br>
          2. 交易手数计算：开仓一手平仓一手，如此来回算一手；<br>
          3. 本活动本公司新客户仅有一次机会参与，不可重复领取；<br>
          4. 凡推广商及其名下客户不参与本活动；<br>
          5. 本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。
        </li>
      </ul>
    </div>
    <div class="dark" v-if="showDialog" @click="showDialog = false"></div>
    <contactus-vue></contactus-vue>
  </div>
</template>

<script>
import ContactusVue from "../../Contactus.vue";
import { getGuid } from '../../../../utils/guid';
import { verifyImgCode, quickRegister } from '../../../../api/info';
export default {
  components: {
    ContactusVue
  },
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      navBarFixed: false,
      scroll: '',
      screenWidth: null,
      navBarFixed: false,
      form: {
        phone: '',
        code: '',
        codeNumber: '',
      },
      // 控制显示验证码或读秒
      verControl: true,
      // 定时器倒计时
      refreshData: null,
      // 验证码读秒数
      verSecond: 60,
      // 控制图片验证码弹窗
      controlImgCodeDialog: false,
      // 图片验证码地址
      imgcodelocal: '',
      setStep: true,
      slider: true,
      showDialog: false,
    }
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  watch: {
    screenWidth: function (n) {
      if (n <= 500) {
        this.$router.push({
          name: "lsy-8901-wap",
        });
      }
    },
  },
  created() {
    this.$emit('controlShow', true);
    this.updateCountdown();
    setInterval(this.updateCountdown, 1000);
  },
  methods: {
    updateCountdown() {
      const today = new Date();
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      const timeLeft = endOfMonth - today;

      this.days = String(Math.floor(timeLeft / (1000 * 60 * 60 * 24))).padStart(2, '0');
      this.hours = String(Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
      this.minutes = String(Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
      this.seconds = String(Math.floor((timeLeft % (1000 * 60)) / 1000)).padStart(2, '0');

    },
    // 跳转客服
    live800() {
      window.open(
        this.$parent.live800UrlUc
      );
    },
    // 返回上级页面
    goback() {
      window.location.href = window.location.origin;
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if (this.scroll > 90) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    // 提交验证码
    confirm(attr) {
      console.log(attr);
      if (attr) {
        let data = {
          Mobile: this.form.phone,
          Token: this.guid,
          ImgCode: Number(attr),
        }
        // 获取图片验证码接口
        verifyImgCode(data).then(res => {
          if (res.ok == 1) {
            this.$message({
              message: res.msg,
              type: 'warning'
            });
            // this.form.code = res.data;
            this.verControl = !this.verControl;
            this.controlImgCodeDialog = false;
          } else {
            this.$message({
              message: res.msg,
              type: 'warning',
            });
            this.controlImgCodeDialog = false;
          }
        })
      } else {
        this.$message({
          message: '请输入图片验证码结果!',
          type: 'warning'
        });
      }
    },
    // 提交手机号验证码
    submit(form) {
      if (form.phone && form.code) {
        let data = {
          IsQuickRregister: true,
          Mobile: form.phone,
          Code: form.code,
          Token: this.guid,
          LoginType: 1,
          AuthMode: 1,
        };
        quickRegister(data).then(res => {
          console.log(res);
          if (res.ok == 1) {
            this.$message({
              message: res.msg,
              type: 'warning',
            });
            window.location.href = 'https://www.rlcproltd.net/uc/login';
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            });
          }
        })
      } else {
        this.$message({
          message: '请输入手机号和验证码！',
          type: 'warning',
        });
      }
    },
    // 点击获取验证码
    verCode(attr) {
      if (this.isPhone(attr)) {
        this.controlImgCodeDialog = true;
        this.countdowm();
        this.guid = getGuid();
        // this.imgcodelocal = window.location.origin + '/api/proxy/v4/U104?key=' + this.guid;
        this.imgcodelocal = 'https://sem.rlcmarketltd.net' + '/api/proxy/v4/U104?key=' + this.guid;
      } else {
        this.$message({
          message: '请输入正确手机号!',
          type: 'warning',
        });
      }
    },
    // 正则判断电话号码格式是否正确
    isPhone(TEL) {
      let strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (strTemp.test(TEL)) {
        return true;
      }
      return false;
    },
    // 读秒
    countdowm() {
      this.refreshData = window.setInterval(() => {
        this.verSecond--;
        if (this.verSecond < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(this.refreshData); //清除定时器
          this.verSecond = 60;
          this.verControl = true;
        }
      }, 1000);
    },
    // 回到顶部
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    //获取当月最后一天的日期
    getLastDay() {
      var year = new Date().getFullYear(); //获取年份
      var month = new Date().getMonth() + 1; //获取月份
      var lastDate = new Date(year, month, 0).getDate(); //获取当月最后一日
      month = month < 10 ? '0' + month : month; //月份补 0
      return year + '年' + month + '月' + lastDate + '日 23:59:59';
    }
  }
}


</script>

<style lang="less" scoped>
.box {
  width: 100%;
  min-width: 1900px;
  height: 5690px;
  background: url('/subject/lsy-8901/bj.png') no-repeat center;
  background-size: 100% 100%;

  .tabbar {
    width: 100%;
    background-color: #ffffff;

    img {
      margin: 20px 50px;
      width: 130px;
    }
  }

  .navBarFixed {
    position: fixed;
    top: 0;
    z-index: 999;
  }

  .banner {
    width: 100%;
    display: block;
  }

  #login {
    width: 70%;
    height: auto;
    background: url(/subject/lsy-8901/zcbj.png);
    border-radius: 10px;
    padding: 25px 0;
    position: absolute;
    top: 600px;
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0 auto;

    .logxiadan {
      width: 50%;
      margin: 0 auto;
      margin-bottom: 10px;
    }

    .interactive {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      input:focus {
        outline: none;
      }

      input {
        width: 30%;
        height: 75px;
        background: #fff;
        border: 1px solid #fff;
        border-radius: 10px;
        font-size: 20px;
        text-indent: 25px;
        list-style: none;
      }

      .codeBox {
        width: 38%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        input {
          width: 100%;
        }

        span {
          width: 160px;
          height: 60px;
          line-height: 60px;
          font-size: 20px;
          border-radius: 10px;
          background: #f36511;
          text-align: center;
          color: #fff;
          position: absolute;
          top: 11px;
          right: 15px;
          cursor: pointer;
        }
      }

      .loginBtn {
        width: 22%;
        height: 80px;
        cursor: pointer;
      }
    }

    .codeDialog {
      width: 500px;
      height: 250px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fee6e4;
      border-radius: 15px;
      z-index: 99;
      border: 1px solid #ffdbdb;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      img {
        width: 120px;
        margin-bottom: 0;
      }

      p {
        span {
          color: #5f0e15;
          font-size: 18px;
          font-weight: 600;
        }

        input {
          width: 150px;
          height: 30px;
          background-color: #ffffff;
          border: none;
          margin-left: 10px;
          text-indent: 10px;
          color: #5f0e15;
          font-size: 16px;
        }
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          width: 100px;
          height: 40px;
          border: 1px solid #f64b44;
          background-color: #ffffff;
          color: #f64b44;
          font-size: 18px;
          border-radius: 8px;
          margin: 0 10px;
        }

        button:nth-child(2) {
          background-color: #f64b44;
          color: #ffffff;
        }
      }
    }

    .logdjs {
      width: 70%;
      margin: 0 auto;
      height: 150px;
      line-height: 150px;
      text-align: center;
      font-size: 40px;
      color: #fff2d2;
      opacity: 0.8;

      .logdjs-span {
        display: inline-block;
        width: 79px;
        height: 91px;
        margin: 0 5px;
        line-height: 91px;
        text-align: center;
        font-size: 49px;
        background-color: #942600;
        border-radius: 16px;
      }

      i {
        font-size: 24px;
      }
    }
  }

  .content {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding-top: 250px;
    margin: 0 auto;

    .parent-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .dujia {
        margin-top: 130px;
      }
    }

    .part {
      margin-top: 30px;
      width: 100%;
      justify-content: center;
      align-items: center;

      .part1 {
        box-sizing: border-box;
        width: 60%;
        height: 1344px;
        margin-top: 10px;
        background: url(/subject/lsy-8901/1.png) no-repeat center center;
        background-size: 100% 100%;
        /* 填充整个小div */
        margin: 0 auto;

        .clearfix::after {
          content: "";
          display: table;
          clear: both;
        }

        .span1 {
          text-decoration: underline;
          position: absolute;
          top: -30px;
          left: 0;
          margin-top: -30px;
          display: block;
          width: 33%;
          text-align: center;
          margin-left: 371px;
          font-size: 24px;
          color: #f36511;
        }

        .img-box1 {
          display: flex;
          justify-content: space-around;
          margin-bottom: 40px;
        }

        .img-box2 {
          margin-bottom: 50px;
          padding-left: 100px;

          .jia {
            margin-bottom: 122px;
            margin-left: 50px;
          }
        }

        .youhui {
          font-size: 32px;
          color: #333;
          margin: 0 auto;
          width: 50%;
          height: 60px;
          line-height: 60px;
          text-align: center;
          display: flex;
          margin-bottom: 20px;

          img {
            margin-right: 10px;
          }

          i {
            font-style: normal;
            /* 取消斜体 */
            color: red;
            /* 设置文本颜色为红色 */
          }
        }

        .neirong {
          width: 50%;
          margin: 0 auto;
          font-size: 32px;

          .name2 {
            color: #f36511;
          }

        }

        .anniu {
          display: flex;
          justify-content: center;
          align-items: center;

          .anniu-div {
            width: 320px;
            height: 88px;
            text-align: center;
            color: #fc5c43;
            font-size: 34px;
            line-height: 88px;
            border: 2px solid #f36511;
            border-radius: 20px;

            span {
              color: #fc5c43;
            }
          }
        }

        .hdtext {
          width: 50%;
          margin: 0 auto;
          font-size: 28px;
          color: #e26060;
        }
      }

      .part2 {
        padding-top: 320px;
        width: 63%;
        height: 1190px;
        margin-top: 10px;
        background: url(/subject/lsy-8901/2.png) no-repeat center center;
        background-size: 100% 100%;
        /* 填充整个小div */
        margin: 0 auto;
        position: relative;

        .clearfix::after {
          content: "";
          display: table;
          clear: both;
        }

        .part2-img {
          position: absolute;
          top: 183px;
          left: 220px;
        }

        .part2-img2 {
          width: 100%;
          text-align: center;
          margin-top: 100px;
          margin-bottom: 40px;
        }

        .part2-text {
          width: 100%;
          width: 50%;
          margin: 0 auto;
          font-size: 32px;
          color: #333333;

          .part2-text-span1 {
            color: #e26060;
          }

          .part2-text-span2 {
            color: #333333;
          }
        }

        .anniu {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 45px;

          .anniu-div {
            width: 320px;
            height: 88px;
            text-align: center;
            color: #fc5c43;
            font-size: 34px;
            line-height: 88px;
            border: 2px solid #f36511;
            border-radius: 20px;

            span {
              color: #fc5c43;
            }
          }
        }
      }

      .part3 {
        box-sizing: border-box;
        height: 1655px;
        padding-top: 165px;
        width: 63%;
        text-align: center;
        margin-top: 10px;
        background: url(/subject/lsy-8901/3.png) no-repeat center center;
        background-size: 100% 100%;
        /* 填充整个小div */
        margin: 0 auto;
        font-size: 32px;
        ;
        color: #333;

        p {
          margin-top: 20px;
          margin-bottom: 10px;

          span {
            color: #e26060;
          }
        }

        .anniu {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 45px;

          .anniu-div {
            width: 320px;
            height: 88px;
            text-align: center;
            color: #fc5c43;
            font-size: 34px;
            line-height: 88px;
            border: 2px solid #f36511;
            border-radius: 20px;

            span {
              color: #fc5c43;
            }
          }
        }

      }
    }
  }

  .sliderBox {
    width: 239px;
    height: 373px;
    background: url(/subject/invest/dialog.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    position: fixed;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
    text-align: center;
    line-height: 470px;

    .allCode {
      width: 140px;
    }

    .user-info-close {
      position: absolute;
      bottom: -60px;
      left: 50%;
      z-index: 5;
      transform: translate(-50%, 0);
      cursor: pointer;
    }
  }

  .dialog {
    position: relative;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 580px;
    box-shadow: 0px 0px 95px 0px rgba(187, 188, 192, 0.1);
    background-color: #fff;
    border-radius: 14px;
    overflow-y: scroll;
    z-index: 99999999;

    .closer {
      width: 25px;
      height: auto;
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
    }

    .dialogContent {
      box-sizing: border-box;
      padding: 15px;
      overflow: hidden;
      background: url('/subject/mayPage/rulesBackground.png') no-repeat;
      background-size: 100% 100%;

      h3 {
        color: #d88f4f;
        text-align: center;
      }

      li {
        font-size: 16px;
        color: #464646;
        line-height: 34px;

        span {
          font-weight: 600;
          color: #d88f4f;
        }

        img {
          width: 100%;
          height: auto;
          margin: 20px 0;
        }

        table {
          width: 100%;
          border: 1px solid #464646;
          border-radius: 10px;

          tr {
            th {
              text-align: center;
              font-weight: 500;
              line-height: 24px;
              border-bottom: 1px solid #464646;
              border-right: 1px solid #464646;
              padding: 5px;
            }

            th:last-child {
              border-right: 1px solid transparent;
            }
          }

          tr:nth-child(1) {
            th {
              font-weight: 600;
            }
          }
        }
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #fedfba;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d88f4f;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }
  }

  .dark {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .5;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 101;
  }
}
</style>